
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as aboutBFZPz73ZvyIfWpnkMrqkm28M4r1MWbQyI6dRvVOzvmkMeta } from "/var/www/www-root/data/www/geonomix.app/pages/about.vue?macro=true";
import { default as indextJSbz4XMJdSd4QpNNhPt_45pAx_Xjh0nOyWRpqXxjnjCMMeta } from "/var/www/www-root/data/www/geonomix.app/pages/index.vue?macro=true";
import { default as loginiyvfaWjUGz30rLFEBppKvJne63Lkbe94wz6X9h3rV8IMeta } from "/var/www/www-root/data/www/geonomix.app/pages/login.vue?macro=true";
import { default as termsTYGDEhBuL7J9BTbqwkDBPFrljYwZ6cPPoYQRDSokfK0Meta } from "/var/www/www-root/data/www/geonomix.app/pages/terms.vue?macro=true";
import { default as policyHr1eNYXbz_45HMfZ8XsScinvM4V_9_cR95_45R5aemtfqFkMeta } from "/var/www/www-root/data/www/geonomix.app/pages/policy.vue?macro=true";
import { default as manualstuxmwTwrgXaIIub0Xz_45EKxNrULivBDlRx3hdn32mspUMeta } from "/var/www/www-root/data/www/geonomix.app/pages/manuals.vue?macro=true";
import { default as contactsJJC9hZM4E_9zAmO0jbdGhORVyVmsefq8TDNubWjIKCMMeta } from "/var/www/www-root/data/www/geonomix.app/pages/contacts.vue?macro=true";
import { default as indexfVIuzusbV7X5DoazoTUaRkM5TDHfgwrICcx7LAn7us8Meta } from "/var/www/www-root/data/www/geonomix.app/pages/docs/index.vue?macro=true";
import { default as index6eB3ZC2_EuKM_NqxVmDhMBwP8ZIn7w_45N0SdkEml2VEsMeta } from "/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/index.vue?macro=true";
import { default as _91section_id_93tyBfCUBcpStFfG5lvgoIZl_avI4Rba2QNF9vqrLZ7F0Meta } from "/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/[section_id].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutBFZPz73ZvyIfWpnkMrqkm28M4r1MWbQyI6dRvVOzvmkMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indextJSbz4XMJdSd4QpNNhPt_45pAx_Xjh0nOyWRpqXxjnjCMMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginiyvfaWjUGz30rLFEBppKvJne63Lkbe94wz6X9h3rV8IMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/login.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsTYGDEhBuL7J9BTbqwkDBPFrljYwZ6cPPoYQRDSokfK0Meta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/terms.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: policyHr1eNYXbz_45HMfZ8XsScinvM4V_9_cR95_45R5aemtfqFkMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/policy.vue")
  },
  {
    name: "manuals",
    path: "/manuals",
    meta: manualstuxmwTwrgXaIIub0Xz_45EKxNrULivBDlRx3hdn32mspUMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/manuals.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: contactsJJC9hZM4E_9zAmO0jbdGhORVyVmsefq8TDNubWjIKCMMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/contacts.vue")
  },
  {
    name: "docs",
    path: "/docs",
    meta: indexfVIuzusbV7X5DoazoTUaRkM5TDHfgwrICcx7LAn7us8Meta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/docs/index.vue")
  },
  {
    name: "docs-chapter_id",
    path: "/docs/:chapter_id()",
    meta: index6eB3ZC2_EuKM_NqxVmDhMBwP8ZIn7w_45N0SdkEml2VEsMeta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/index.vue")
  },
  {
    name: "docs-chapter_id-section_id",
    path: "/docs/:chapter_id()/:section_id()",
    meta: _91section_id_93tyBfCUBcpStFfG5lvgoIZl_avI4Rba2QNF9vqrLZ7F0Meta || {},
    component: () => import("/var/www/www-root/data/www/geonomix.app/pages/docs/[chapter_id]/[section_id].vue")
  }
]